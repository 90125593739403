import { lazy } from "react";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";

// dashboard routing
const Dashboard = Loadable(lazy(() => import("views/dashboard")));

// application - user social & account profile routing

const AccountProfile = Loadable(
  lazy(() => import("views/account-profile/index"))
);

// banner routing
const Banner = Loadable(lazy(() => import("views/banner")));
const AddBanner = Loadable(lazy(() => import("views/banner/add-banner")));
const EditBanner = Loadable(lazy(() => import("views/banner/edit-banner")));

// calendar routing
const Calendar = Loadable(lazy(() => import("views/calendar")));
const AddCalendar = Loadable(lazy(() => import("views/calendar/add-calendar")));
const EditCalendar = Loadable(
  lazy(() => import("views/calendar/edit-calendar"))
);

// notification routing
const Notification = Loadable(lazy(() => import("views/notification")));
const AddNotification = Loadable(
  lazy(() => import("views/notification/add-notification"))
);
const EditNotification = Loadable(
  lazy(() => import("views/notification/edit-notification"))
);

// pooja routing
const Pooja = Loadable(lazy(() => import("views/pooja")));
const AddPooja = Loadable(lazy(() => import("views/pooja/add-pooja")));
const EditPooja = Loadable(lazy(() => import("views/pooja/edit-pooja")));

//user routes
const User = Loadable(lazy(() => import("views/user")));
const EditUser = Loadable(lazy(() => import("views/user/edit-user")));

//tithi routes
const Tithi = Loadable(lazy(() => import("views/tithi")));
const AddTithi = Loadable(lazy(() => import("views/tithi/add-tithi")));
const EditTithi = Loadable(lazy(() => import("views/tithi/edit-tithi")));

//gallery routes
const Gallery = Loadable(lazy(() => import("views/gallery")));
const AddGallery = Loadable(lazy(() => import("views/gallery/add-gallery")));
const EditGallery = Loadable(lazy(() => import("views/gallery/edit-gallery")));
const BookingHistory = Loadable(lazy(() => import("views/booking-history")));

//setting routes
const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "/dashboard",
      element: <Dashboard />,
    },
    {
      path: "/banner",
      element: <Banner />,
    },
    {
      path: "/add-banner",
      element: <AddBanner />,
    },

    {
      path: "/edit-banner/:id",
      element: <EditBanner />,
    },
    {
      path: "/calendar",
      element: <Calendar />,
    },
    {
      path: "/add-calendar",
      element: <AddCalendar />,
    },

    {
      path: "/edit-calendar/:id",
      element: <EditCalendar />,
    },
    {
      path: "/pooja-list",
      element: <Pooja />,
    },
    {
      path: "/add-pooja",
      element: <AddPooja />,
    },
    {
      path: "/edit-pooja/:id",
      element: <EditPooja />,
    },
    {
      path: "/notification",
      element: <Notification />,
    },
    {
      path: "/add-notification",
      element: <AddNotification />,
    },

    {
      path: "/edit-notification/:id",
      element: <EditNotification />,
    },
    {
      path: "/account-profile",
      element: <AccountProfile />,
    },
    {
      path: "/users",
      element: <User />,
    },
    {
      path: "/booking-history",
      element: <BookingHistory />,
    },
    {
      path: "/edit-user/:id",
      element: <EditUser />,
    },
    {
      path: "/tithi",
      element: <Tithi />,
    },
    {
      path: "/add-tithi",
      element: <AddTithi />,
    },
    {
      path: "/edit-tithi/:id",
      element: <EditTithi />,
    },
    {
      path: "/gallery",
      element: <Gallery />,
    },
    {
      path: "/add-gallery",
      element: <AddGallery />,
    },

    {
      path: "/edit-gallery/:id",
      element: <EditGallery />,
    },
  ],
};

export default MainRoutes;
