import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  Calendar: [],
};

//internally using immer lib (can create mutable state)
export const calendarSlice = createSlice({
  name: "calendarData",
  initialState,
  reducers: {
    updateAllCalendar: (state, action) => {
      state.Calendar = action.payload;
    },
  },
});

// this is for dispatch
export const { updateCalendar, updateToken, updateAllCalendar } =
  calendarSlice.actions;

// this is for configureStore
export default calendarSlice.reducer;
