import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  Pooja: [],
};

//internally using immer lib (can create mutable state)
export const poojaSlice = createSlice({
  name: "poojaData",
  initialState,
  reducers: {
    updateAllPooja: (state, action) => {
      state.Pooja = action.payload;
    },
  },
});

// this is for dispatch
export const { updateAllPooja } = poojaSlice.actions;

// this is for configureStore
export default poojaSlice.reducer;
