// third-party
import { combineReducers } from "redux";

// project imports
import snackbarReducer from "./slices/snackbar";
import menuReducer from "./slices/menu";

import userReducer from "../redux/redux-slice/user.slice";
import bannerReducer from "../redux/redux-slice/banner.slice";
import calendarReducer from "../redux/redux-slice/calendar.slice";
import notificationReducer from "../redux/redux-slice/notification.slice";
import poojaReducer from "../redux/redux-slice/pooja.slice";
import tithiReducer from "../redux/redux-slice/tithi.slice";
import galleryReducer from "redux/redux-slice/gallery.slice";
import bookingHistory from "redux/redux-slice/bookinghistory.slice";
// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  snackbar: snackbarReducer,

  menu: menuReducer,
  user: userReducer,
  banner: bannerReducer,
  calendar: calendarReducer,
  gallery: galleryReducer,
  notification: notificationReducer,
  pooja: poojaReducer,
  tithi: tithiReducer,
  bookingHistory: bookingHistory,
});

export default reducer;
