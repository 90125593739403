import { getTokenLocal } from "../../utils/localStorage.util";
import ApiRoutes from "../../configs/endpoints.config";
import HttpClient from "../index.api";
const baseURL = process.env.REACT_APP_API_URL;
class Auth extends HttpClient {
  constructor() {
    super(baseURL);
    this._initializeRequestInterceptor();
    this._initializeResponseInterceptor();
  }

  _initializeRequestInterceptor = () => {
    this.instance.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${getTokenLocal()}`;
      return config;
    });
  };

  _initializeResponseInterceptor = () => {
    this.instance.interceptors.response.use(
      (response) => {
        return response;
      },
      (response) => {
        return Promise.resolve(response);
      }
    );
  };

  loginConfig = ApiRoutes.Auth.Login;
  profileConfig = ApiRoutes.Auth.Profile;
  changePasswordConfig = ApiRoutes.Auth.ChangePassword;
  updateProfileConfig = ApiRoutes.Auth.UpdateProfile;

  login = async (reqBody) => {
    return this.instance({
      method: this.loginConfig.Method,
      url: this.loginConfig.Endpoint,
      headers: {},
      data: reqBody,
    });
  };
  getProfile = async (reqBody) => {
    return this.instance({
      method: this.profileConfig.Method,
      url: this.profileConfig.Endpoint,
      headers: {},
      data: reqBody,
    });
  };
  changePassword = async (reqBody) => {
    return this.instance({
      method: this.changePasswordConfig.Method,
      url: this.changePasswordConfig.Endpoint,
      headers: {},
      data: reqBody,
    });
  };
  updateProfile = async (reqBody) => {
    return this.instance({
      method: this.updateProfileConfig.Method,
      url: this.updateProfileConfig.Endpoint,
      headers: {},
      data: reqBody,
    });
  };
}

export default Auth;
