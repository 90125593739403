// third-party
import { FormattedMessage } from "react-intl";

// assets
import {
  IconPhotoPlus,
  IconCalendar,
  IconBell,
  IconBuildingChurch,
  IconApps,
  IconUserCheck,
  IconCalendarEvent,
  IconBrandGooglePhotos,
} from "@tabler/icons";

// constant
const icons = {
  IconPhotoPlus,
  IconCalendar,
  IconBell,
  IconBuildingChurch,
  IconApps,
  IconUserCheck,
  IconCalendarEvent,
  IconBrandGooglePhotos,
};

// ==============================|| APPLICATION MENU ITEMS ||============================== //

const utilities = {
  id: "Utlities",
  title: <FormattedMessage id="Utlities" />,
  type: "group",
  children: [
    {
      id: "banner",
      title: <FormattedMessage id="Banner" />,
      type: "item",
      icon: icons.IconPhotoPlus,
      url: "/banner",
    },
    {
      id: "tithi",
      title: <FormattedMessage id="Tithi" />,
      type: "item",
      icon: icons.IconCalendarEvent,
      url: "/tithi",
    },
    {
      id: "calendar",
      title: <FormattedMessage id="Calendar" />,
      type: "item",
      icon: icons.IconCalendar,
      url: "/calendar",
    },
    {
      id: "gallery",
      title: <FormattedMessage id="Gallery" />,
      type: "item",
      icon: icons.IconBrandGooglePhotos,
      url: "/gallery",
    },
    {
      id: "pooja",
      title: <FormattedMessage id="Pooja" />,
      type: "collapse",
      icon: icons.IconBuildingChurch,
      children: [
        {
          id: "pooja-list",
          title: <FormattedMessage id="Pooja List" />,
          type: "item",
          url: "/pooja-list",
        },
        {
          id: "add-pooja",
          title: <FormattedMessage id="Add Pooja" />,
          type: "item",
          url: "/add-pooja",
        },
      ],
    },

    {
      id: "notification",
      title: <FormattedMessage id="Notification" />,
      type: "item",
      icon: icons.IconBell,
      url: "/notification",
    },

    {
      id: "users",
      title: <FormattedMessage id="users" />,
      type: "item",
      icon: icons.IconUserCheck,
      url: "/users",
    },
    {
      id: "bookinghistory",
      title: <FormattedMessage id="Booking History" />,
      type: "item",
      icon: icons.IconUserCheck,
      url: "/booking-history",
    },
  ],
};

export default utilities;
