import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  bookingHistory: [],
};

//internally using immer lib (can create mutable state)
export const bookingHistorySlice = createSlice({
  name: "bookingHistoryData",
  initialState,
  reducers: {
    updateAllbookingHistory: (state, action) => {
      state.bookingHistory = action.payload;
    },
  },
});

// this is for dispatch
export const { updateAllbookingHistory } = bookingHistorySlice.actions;

// this is for configureStore
export default bookingHistorySlice.reducer;
