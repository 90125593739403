import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  Tithi: [],
};

//internally using immer lib (can create mutable state)
export const tithiSlice = createSlice({
  name: "tithiData",
  initialState,
  reducers: {
    updateAllTithi: (state, action) => {
      state.Tithi = action.payload;
    },
  },
});

// this is for dispatch
export const { updateAllTithi } = tithiSlice.actions;

// this is for configureStore
export default tithiSlice.reducer;
