import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  Gallery: [],
};

//internally using immer lib (can create mutable state)
export const gallerySlice = createSlice({
  name: "galleryData",
  initialState,
  reducers: {
    updateAllGallery: (state, action) => {
      state.Gallery = action.payload;
    },
  },
});

// this is for dispatch
export const { updateAllGallery } = gallerySlice.actions;

// this is for configureStore
export default gallerySlice.reducer;
