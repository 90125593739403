export const HttpMethod = {
  Get: "GET",
  Post: "POST",
  Put: "PUT",
  Patch: "PATCH",
  Delete: "DELETE",
};

const ApiRoutes = {
  Dashboard: {
    Data: {
      Endpoint: "/auth/dashboard",
      Method: HttpMethod.Post,
    },
  },
  Auth: {
    Login: {
      Endpoint: "/auth/login",
      Method: HttpMethod.Post,
    },
    Profile: {
      Endpoint: "/auth/getProfile",
      Method: HttpMethod.Post,
    },
    UpdateProfile: {
      Endpoint: "/auth/updateProfile",
      Method: HttpMethod.Post,
    },
    ChangePassword: {
      Endpoint: "/auth/changePassword",
      Method: HttpMethod.Post,
    },
  },
  Banner: {
    All: {
      Endpoint: "banner/getAllBanner",
      Method: HttpMethod.Post,
    },
    BannerById: {
      Endpoint: "banner/getBannerById",
      Method: HttpMethod.Post,
    },
    AddBanner: {
      Endpoint: "banner/addBanner",
      Method: HttpMethod.Post,
    },
    EditBanner: {
      Endpoint: "banner/editBanner",
      Method: HttpMethod.Post,
    },
    DeleteBanner: {
      Endpoint: "banner/deleteBanner",
      Method: HttpMethod.Post,
    },
  },
  Calendar: {
    All: {
      Endpoint: "calendar/getAllCalendar",
      Method: HttpMethod.Post,
    },
    CalendarById: {
      Endpoint: "calendar/getCalendarById",
      Method: HttpMethod.Post,
    },
    AddCalendar: {
      Endpoint: "calendar/addCalendar",
      Method: HttpMethod.Post,
    },
    EditCalendar: {
      Endpoint: "calendar/editCalendar",
      Method: HttpMethod.Post,
    },
    DeleteCalendar: {
      Endpoint: "calendar/deleteCalendar",
      Method: HttpMethod.Post,
    },
  },
  Gallery: {
    All: {
      Endpoint: "gallery/getAllGallery",
      Method: HttpMethod.Post,
    },
    GalleryById: {
      Endpoint: "gallery/getGalleryById",
      Method: HttpMethod.Post,
    },
    AddGallery: {
      Endpoint: "gallery/addGallery",
      Method: HttpMethod.Post,
    },
    EditGallery: {
      Endpoint: "gallery/editGallery",
      Method: HttpMethod.Post,
    },
    DeleteGallery: {
      Endpoint: "gallery/deleteGallery",
      Method: HttpMethod.Post,
    },
  },
  Tithi: {
    All: {
      Endpoint: "tithi/getAllTithi",
      Method: HttpMethod.Post,
    },
    TithiById: {
      Endpoint: "tithi/getTithiById",
      Method: HttpMethod.Post,
    },
    AddTithi: {
      Endpoint: "tithi/addTithi",
      Method: HttpMethod.Post,
    },
    EditTithi: {
      Endpoint: "tithi/editTithi",
      Method: HttpMethod.Post,
    },
    DeleteTithi: {
      Endpoint: "tithi/deleteTithi",
      Method: HttpMethod.Post,
    },
  },
  Pooja: {
    All: {
      Endpoint: "pooja/getAllPooja",
      Method: HttpMethod.Post,
    },
    PoojaById: {
      Endpoint: "pooja/getPoojaById",
      Method: HttpMethod.Post,
    },
    AddPooja: {
      Endpoint: "pooja/addPooja",
      Method: HttpMethod.Post,
    },
    EditPooja: {
      Endpoint: "pooja/editPooja",
      Method: HttpMethod.Post,
    },
    DeletePooja: {
      Endpoint: "pooja/deletePooja",
      Method: HttpMethod.Post,
    },
  },
  Seva: {
    All: {
      Endpoint: "seva/getAllSeva",
      Method: HttpMethod.Post,
    },
    SevaById: {
      Endpoint: "seva/getSevaById",
      Method: HttpMethod.Post,
    },
    SevaByPoojaId: {
      Endpoint: "seva/getSevaByPoojaId",
      Method: HttpMethod.Post,
    },
    AddSeva: {
      Endpoint: "seva/addSeva",
      Method: HttpMethod.Post,
    },
    EditSeva: {
      Endpoint: "seva/editSeva",
      Method: HttpMethod.Post,
    },
    DeleteSeva: {
      Endpoint: "seva/deleteSeva",
      Method: HttpMethod.Post,
    },
  },
  Notification: {
    All: {
      Endpoint: "notification/getAllNotification",
      Method: HttpMethod.Post,
    },
    NotificationById: {
      Endpoint: "notification/getNotificationById",
      Method: HttpMethod.Post,
    },
    AddNotification: {
      Endpoint: "notification/addNotification",
      Method: HttpMethod.Post,
    },
    EditNotification: {
      Endpoint: "notification/editNotification",
      Method: HttpMethod.Post,
    },
    DeleteNotification: {
      Endpoint: "notification/deleteNotification",
      Method: HttpMethod.Post,
    },
  },
  User: {
    All: {
      Endpoint: "user/getAllUser",
      Method: HttpMethod.Post,
    },
    UserById: {
      Endpoint: "user/getUserById",
      Method: HttpMethod.Post,
    },
    AddUser: {
      Endpoint: "user/createUser",
      Method: HttpMethod.Post,
    },
    EditUser: {
      Endpoint: "user/editUser",
      Method: HttpMethod.Post,
    },
    DeleteUser: {
      Endpoint: "user/deleteUser",
      Method: HttpMethod.Post,
    },
    allPurchase: {
      Endpoint: "purchase/allPurchase",
      Method: HttpMethod.Post,
    },
  },
  MemberList: {
    All: {
      Endpoint: "voter/getAllVoter",
      Method: HttpMethod.Post,
    },
    MemberById: {
      Endpoint: "voter/getVoterById",
      Method: HttpMethod.Post,
    },
    FamilyMemberById: {
      Endpoint: "voter/getVoterByFamilyId",
      Method: HttpMethod.Post,
    },
    AddMember: {
      Endpoint: "voter/createVoter",
      Method: HttpMethod.Post,
    },
    EditMember: {
      Endpoint: "voter/editVoter",
      Method: HttpMethod.Post,
    },
    DeleteMember: {
      Endpoint: "voter/deleteVoter",
      Method: HttpMethod.Post,
    },
  },
  FamilyList: {
    All: {
      Endpoint: "family/getAllFamily",
      Method: HttpMethod.Post,
    },
    FamilyById: {
      Endpoint: "family/getFamilyById",
      Method: HttpMethod.Post,
    },
    AddFamily: {
      Endpoint: "family/createFamily",
      Method: HttpMethod.Post,
    },
    EditFamily: {
      Endpoint: "family/editFamily",
      Method: HttpMethod.Post,
    },
    DeleteFamily: {
      Endpoint: "family/deleteFamily",
      Method: HttpMethod.Post,
    },
  },
};

export default ApiRoutes;
