import { lazy } from "react";

// project imports
import Loadable from "ui-component/Loadable";

// login option 3 routing
const AuthLogin = Loadable(
  lazy(() => import("views/pages/authentication/authentication3/Login"))
);
const AuthForgotPassword3 = Loadable(
  lazy(() =>
    import("views/pages/authentication/authentication3/ForgotPassword3")
  )
);
const AuthResetPassword3 = Loadable(
  lazy(() =>
    import("views/pages/authentication/authentication3/ResetPassword3")
  )
);
// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
  path: "/",
  element: <AuthLogin />,
};

export default AuthenticationRoutes;
