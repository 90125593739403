import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  Notification: [],
};

//internally using immer lib (can create mutable state)
export const notificationSlice = createSlice({
  name: "notificationData",
  initialState,
  reducers: {
    updateAllNotification: (state, action) => {
      state.Notification = action.payload;
    },
  },
});

// this is for dispatch
export const { updateNotification, updateToken, updateAllNotification } =
  notificationSlice.actions;

// this is for configureStore
export default notificationSlice.reducer;
